import React from 'react';
import { connect } from 'react-redux';

import { Route, Redirect, Switch as SwitchR } from 'react-router-dom';
import { Layout } from 'antd';
import ResultView from '@/components/dashboard/dashboard.resultView';
// import UserView from '@/components/dashboard/dashboard.userView2';
import ViewsList from '@/components/dashboard/dashboard.views';
import Dashboard from '@/components/dashboard/dashboard.default';

import style from '@/style/containers/dashboard.less'
import PropTypes from 'prop-types';
import { SysUIConfig } from '@/constants/sys.config';
import BadgeInfo from '@/components/mainView/main.badgeInfo';
import PB from '@/libs/simplePB';
import intl from 'react-intl-universal';

const {Header, Content} = Layout;

@connect(
	state => ({
		loginStatus: state.account.loginStatus,
		userInfo: state.account.userInfo,
	}),
)
class DashBoard extends React.Component {
	// 本组件路由前缀
	routePath = '/mainview/dashboard';
	title = intl.get('Custom.view.list');

	state = {
		searchWord: '', // 搜索关键字
		topMenus: intl.get('locale')==='zh-cn'?[
			// {name: '推荐图谱', path: `${this.routePath}/recommend`},
			// {name: '公开图谱', path: `${this.routePath}/published`},
			// {name: '首页', path: `${this.routePath}/default`},
			// {name: '收费图谱', path: `${this.routePath}/published/nofree`},
			// {name: '全部', path: `${this.routePath}/all`, module: 'all'},
			{name: intl.get('Custom.channel.sysName')[0], path: `${this.routePath}/userview`, module: 'my'},
			{name: intl.get('Custom.channel.sysName')[1], path: `${this.routePath}/teamwork`, module: 'teamwork'},
			{name: intl.get('Custom.channel.sysName')[2], path: `${this.routePath}/public`, module: 'public'},
			{name: intl.get('Custom.channel.sysName')[3], path: `${this.routePath}/cooperation`, module: 'cooperation'},
			{name: intl.get('Custom.channel.sysName')[4], path: `${this.routePath}/free`, module: 'free'},
			//{name: '事件关联', path: `${this.routePath}/eventcorrelation`, module: 'eventcorrelation'},
			{name: '领导讲话', path: `${this.routePath}/leaderspeech`, module: 'leaderspeech'},
			{name: '特征表述', path: `${this.routePath}/kpofspeech`, module: 'kpofspeech'},
			{name: '领导会客', path: `${this.routePath}/leaderreceiveguests`, module: 'leaderreceiveguests'},
			{name: '领导调研', path: `${this.routePath}/leaderresearch`, module: 'leaderresearch'},
			{name: '重大项目', path: `${this.routePath}/majorproject`, module: 'majorproject'},
			{name: '产业政策', path: `${this.routePath}/industrialpolicy`, module: 'industrialpolicy'},
			{name: '先进典型', path: `${this.routePath}/advancedmodel`, module: 'advancedmodel'},
			{name: '试点示范', path: `${this.routePath}/demonstration`, module: 'demonstration'},
			{name: '专项行动', path: `${this.routePath}/specialoperations`, module: 'specialoperations'},
			//{name: '前沿成果', path: `${this.routePath}/achievement`, module: 'achievement'},
			//{name: '获奖信息', path: `${this.routePath}/award`, module: 'award'},
			//{name: '嘉宾演讲', path: `${this.routePath}/guestspeech`, module: 'guestspeech'},
			//{name: '科研合作', path: `${this.routePath}/scientific`, module: 'scientific'},
			{name: '党报评论', path: `${this.routePath}/partypaper`, module: 'partypaper'},
			{name: '部委报告', path: `${this.routePath}/ministriy`, module: 'ministriy'},
			{name: '权威解读', path: `${this.routePath}/interpret`, module: 'interpret'},
			{name: '校友活动', path: `${this.routePath}/alumnievent`, module: 'alumnievent'},
			{name: '老乡活动', path: `${this.routePath}/hometownevent`, module: 'hometownevent'},
			{name: '基层治理', path: `${this.routePath}/grassroots_governance`, module: 'grassroots_governance'},
			{name: intl.get('Custom.channel.sysName')[5], path: `${this.routePath}/latest`, module: 'latest'},
			// {name: '搜索图谱', path: `${this.routePath}/result`},
		]:[
			{name: intl.get('Custom.channel.sysName')[0], path: `${this.routePath}/userview`, module: 'my'},
			{name: intl.get('Custom.channel.sysName')[1], path: `${this.routePath}/teamwork`, module: 'teamwork'},
			{name: intl.get('Custom.channel.sysName')[2], path: `${this.routePath}/public`, module: 'public'},
			{name: intl.get('Custom.channel.sysName')[3], path: `${this.routePath}/cooperation`, module: 'cooperation'},
			{name: intl.get('Custom.channel.sysName')[4], path: `${this.routePath}/free`, module: 'free'},
			{name: intl.get('Custom.channel.sysName')[5], path: `${this.routePath}/latest`, module: 'latest'},
			// {name: '搜索图谱', path: `${this.routePath}/result`},
		],
		contentWrapSpacing: 100, // 计算图谱列表容器左右间距
	};

	componentWillMount() {
		const {loginStatus} = this.props;
		if (loginStatus !== 0) {
			this.props.history.push('/login');
		}
	}

	componentDidMount() {
		this.handleResize();
		window.addEventListener('resize', this.handleResize); //监听窗口大小改变

		if (this.props.url.includes('/mainview/dashboard/search')) {
			let historySearchWord = this.props.history.location && this.props.history.location.state && this.props.history.location.state.searchWord;
			this.setState({
				searchWord: historySearchWord,
			})
		}

		PB.sub(this, 'search', 'search.view.searchWord', ({searchWord}) => {
			this.setState({
				searchWord: searchWord,
			})
		})
	}

	componentWillUnmount() {
		PB.remove(this);
		window.removeEventListener('resize', this.handleResize) // 取消监听窗口大小变化
	}

	handleResize = () => {
		let htmlFontSize = 14, // html的响应变化基准
				windowWidth = window.innerWidth,
				viewInfoCardWidth = 26; // 图谱卡片占的宽度，单位rem，需要跟据html的响应变化基准
		// 参照css定义的html的响应设置
		if (windowWidth <= 1920) {
			htmlFontSize = 14;
		} else if (windowWidth > 1920 && windowWidth <= 3999) {
			htmlFontSize = 18;
		} else if (windowWidth > 4000) {
			htmlFontSize = 24;
		}

		const cardWidth = viewInfoCardWidth * htmlFontSize;
		this.setState({
			contentWrapSpacing: (windowWidth - Math.floor(windowWidth / cardWidth) * cardWidth) / 2,
		})
	};

	// 搜索 所有图谱
	searchView = (keyWord) => {
		this.setState({
			searchWord: keyWord,
		});
	};

	render() {
		// 顶部按钮
		const { topMenus, contentWrapSpacing } = this.state;
		// 传入参数
		const { channel = 0 } = this.props;

		return (
			<Layout className={style['dashboard-layout-wrap']}>
				<Header className={style['header']}>
					<div className={style['logo']}>
						<img
							onClick={() => {
								this.props.history.push({pathname: '/index', state: {type: 'intro'}})
							}}
							src={intl.get('locale')==='zh-cn'?'/assets/logo-hans.png':'/assets/logo-hans_en.png'}
							alt={intl.get('Custom.general.title')}
						/>
					</div>
					<BadgeInfo currentUserInfo={this.props.userInfo}
					           doChangePwd={this.props.doChangePwd}
					           onLogout={this.props.onLogout}
					           history={this.props.history}
					           changeUserBasicInfoStatus={this.props.changeUserBasicInfoStatus}
					           onSaveUserBasicInfo={this.props.onSaveUserBasicInfo}
					           style={{
					           	visibility: SysUIConfig.BadgeInfo.visibility,
						           // top: '17px',
					           }}
					/>
				</Header>
				<Content>
					<SwitchR>
						{/*<Route path={`${this.routePath}/recommend`} render={() => null}/>*/}
						<Route path={`${this.routePath}/default`} render={({history}) => (
							<Dashboard
								title={intl.get('Custom.general.title')}
								history={history}
							/>
						)}/>

						<Route path={`${this.routePath}/free`} render={({history}) => (
							// 公开复制
							<ViewsList
								history={history}
								module={'free'}
								channel={channel}
								navOptionList={topMenus}
								contentWrapSpacing={contentWrapSpacing}
							/>
						)}/>
						<Route path={`${this.routePath}/all`} render={({history}) => (
							// 全部
							<ViewsList
								history={history}
								module={'all'}
								channel={channel}
								navOptionList={topMenus}
								contentWrapSpacing={contentWrapSpacing}
							/>
						)}/>
						<Route path={`${this.routePath}/public`} render={({history}) => (
							// 公开阅读
							<ViewsList
								history={history}
								module={'public'}
								channel={channel}
								navOptionList={topMenus}
								contentWrapSpacing={contentWrapSpacing}
							/>
						)}/>
						<Route path={`${this.routePath}/userview`} render={({history}) => (
							// 我创建的
							<ViewsList
								history={history}
								module={'my'}
								channel={channel}
								navOptionList={topMenus}
								contentWrapSpacing={contentWrapSpacing}
							/>
						)}/>
						<Route path={`${this.routePath}/teamwork`} render={({history}) => (
							// 我参与的
							<ViewsList
								history={history}
								module={'teamwork'}
								channel={channel}
								navOptionList={topMenus}
								contentWrapSpacing={contentWrapSpacing}
							/>
						)}/>
						<Route path={`${this.routePath}/cooperation`} render={({history}) => (
							// 开放协作
							<ViewsList
								history={history}
								module={'cooperation'}
								channel={channel}
								navOptionList={topMenus}
								contentWrapSpacing={contentWrapSpacing}
							/>
						)}/>
						<Route path={`${this.routePath}/eventcorrelation`} render={({history}) => (
							// 事件关联
							<ViewsList
								history={history}
								module={'eventcorrelation'}
								channel={channel}
								navOptionList={topMenus}
								contentWrapSpacing={contentWrapSpacing}
							/>
						)}/>
						<Route path={`${this.routePath}/leaderspeech`} render={({history}) => (
							<ViewsList
								history={history}
								module={'leaderspeech'}
								channel={3}
								navOptionList={topMenus}
								contentWrapSpacing={contentWrapSpacing}
							/>
						)}/>
						<Route path={`${this.routePath}/majorproject`} render={({history}) => (
							<ViewsList
								history={history}
								module={'majorproject'}
								channel={5}
								navOptionList={topMenus}
								contentWrapSpacing={contentWrapSpacing}
							/>
						)}/>
						<Route path={`${this.routePath}/advancedmodel`} render={({history}) => (
							<ViewsList
								history={history}
								module={'advancedmodel'}
								channel={4}
								navOptionList={topMenus}
								contentWrapSpacing={contentWrapSpacing}
							/>
						)}/>
						<Route path={`${this.routePath}/demonstration`} render={({history}) => (
							<ViewsList
								history={history}
								module={'demonstration'}
								channel={8}
								navOptionList={topMenus}
								contentWrapSpacing={contentWrapSpacing}
							/>
						)}/>
						<Route path={`${this.routePath}/achievement`} render={({history}) => (
							<ViewsList
								history={history}
								module={'achievement'}
								channel={6}
								navOptionList={topMenus}
								contentWrapSpacing={contentWrapSpacing}
							/>
						)}/>
						<Route path={`${this.routePath}/specialoperations`} render={({history}) => (
							<ViewsList
								history={history}
								module={'specialoperations'}
								channel={23}
								navOptionList={topMenus}
								contentWrapSpacing={contentWrapSpacing}
							/>
						)}/>
						<Route path={`${this.routePath}/kpofspeech`} render={({history}) => (
							<ViewsList
								history={history}
								module={'kpofspeech'}
								channel={24}
								navOptionList={topMenus}
								contentWrapSpacing={contentWrapSpacing}
							/>
						)}/>
						<Route path={`${this.routePath}/partypaper`} render={({history}) => (
							<ViewsList
								history={history}
								module={'partypaper'}
								channel={7}
								navOptionList={topMenus}
								contentWrapSpacing={contentWrapSpacing}
							/>
						)}/>
						<Route path={`${this.routePath}/ministriy`} render={({history}) => (
							<ViewsList
								history={history}
								module={'ministriy'}
								channel={19}
								navOptionList={topMenus}
								contentWrapSpacing={contentWrapSpacing}
							/>
						)}/>
						<Route path={`${this.routePath}/interpret`} render={({history}) => (
							<ViewsList
								history={history}
								module={'interpret'}
								channel={20}
								navOptionList={topMenus}
								contentWrapSpacing={contentWrapSpacing}
							/>
						)}/>
						<Route path={`${this.routePath}/grassroots_governance`} render={({history}) => (
							<ViewsList
								history={history}
								module={'grassroots_governance'}
								channel={13}
								navOptionList={topMenus}
								contentWrapSpacing={contentWrapSpacing}
							/>
						)}/>
						<Route path={`${this.routePath}/scientific`} render={({history}) => (
							<ViewsList
								history={history}
								module={'scientific'}
								channel={9}
								navOptionList={topMenus}
								contentWrapSpacing={contentWrapSpacing}
							/>
						)}/>
						<Route path={`${this.routePath}/leaderreceiveguests`} render={({history}) => (
							<ViewsList
								history={history}
								module={'leaderreceiveguests'}
								channel={10}
								navOptionList={topMenus}
								contentWrapSpacing={contentWrapSpacing}
							/>
						)}/>
						<Route path={`${this.routePath}/leaderresearch`} render={({history}) => (
							<ViewsList
								history={history}
								module={'leaderresearch'}
								channel={18}
								navOptionList={topMenus}
								contentWrapSpacing={contentWrapSpacing}
							/>
						)}/>
						<Route path={`${this.routePath}/award`} render={({history}) => (
							<ViewsList
								history={history}
								module={'award'}
								channel={11}
								navOptionList={topMenus}
								contentWrapSpacing={contentWrapSpacing}
							/>
						)}/>
						<Route path={`${this.routePath}/guestspeech`} render={({history}) => (
							<ViewsList
								history={history}
								module={'guestspeech'}
								channel={12}
								navOptionList={topMenus}
								contentWrapSpacing={contentWrapSpacing}
							/>
						)}/>
						<Route path={`${this.routePath}/alumnievent`} render={({history}) => (
							<ViewsList
								history={history}
								module={'alumnievent'}
								channel={14}
								navOptionList={topMenus}
								contentWrapSpacing={contentWrapSpacing}
							/>
						)}/>
						<Route path={`${this.routePath}/hometownevent`} render={({history}) => (
							<ViewsList
								history={history}
								module={'hometownevent'}
								channel={15}
								navOptionList={topMenus}
								contentWrapSpacing={contentWrapSpacing}
							/>
						)}/>
						<Route path={`${this.routePath}/industrialpolicy`} render={({history}) => (
							<ViewsList
								history={history}
								module={'industrialpolicy'}
								channel={16}
								navOptionList={topMenus}
								contentWrapSpacing={contentWrapSpacing}
							/>
						)}/>
						<Route path={`${this.routePath}/latest`} render={({history}) => (
							<ViewsList
								history={history}
								module={'latest'}
								channel={channel}
								navOptionList={topMenus}
								contentWrapSpacing={contentWrapSpacing}
							/>
						)}/>
						<Route path={`${this.routePath}/video`} render={({history}) => (
							<ViewsList
								history={history}
								module={'video'}
								navOptionList={topMenus}
								contentWrapSpacing={contentWrapSpacing}
							/>
						)}/>
						<Route path={`${this.routePath}/search`} render={(props) => (
							// 搜索看板
							<ResultView
								{...props}
								history={props.history}
								currentPath={`${this.routePath}/search`}
								searchWord={this.state.searchWord}
								searchParams={{
									word: this.state.searchWord,
									channel: channel,
								  }}
								search={this.searchView}
								navOptionList={topMenus}
								contentWrapSpacing={contentWrapSpacing}
							/>
						)}/>
						<Route render={() => (<Redirect to={topMenus[0].path}/>)}/>
					</SwitchR>
				</Content>
			</Layout>
		);
	}
}

DashBoard.defaultProps = {
	params:{}
};

DashBoard.propTypes = {
	url: PropTypes.string,
	userInfo: PropTypes.object,
	loginStatus: PropTypes.number,
	history: PropTypes.object,
	onLogout: PropTypes.func,
	doChangePwd: PropTypes.func,
	changeUserBasicInfoStatus: PropTypes.number,
	onSaveUserBasicInfo: PropTypes.func,
	changePwdStatus: PropTypes.number,
	removingViewList: PropTypes.array,
	resetChangePwdStatus: PropTypes.func,
	params:PropTypes.object // 一些非必须的参数
};

export default DashBoard;

